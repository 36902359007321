'use client'
import React, { Suspense } from "react";
import { ReactNode } from "react";

const Loader = ({ children }: { children: ReactNode }) => {
    return (
        <Suspense fallback={<div className="flex h-screen items-center justify-center bg-white dark:bg-black">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>}>
            {children}
        </Suspense>
    );
};

export default Loader;
